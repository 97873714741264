import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { lazyRetry } from "utils/lazy";

import BasicLayout from "layout/BasicLayout";
import AuthLayout from "layout/AuthLayout";
import ProjectLayout from "layout/ProjectLayout";

const Login = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Login" */ "./pages/Session/Login")
  )
);

const Logout = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Logout" */ "./pages/Session/Logout")
  )
);

const ResetPassword = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ResetPassword" */ "./pages/Session/ResetPassword"
    )
  )
);

const ChangePassword = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ChangePassword" */ "./pages/Session/ChangePassword"
    )
  )
);

const FirstLogin = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "FirstLogin" */ "./pages/Session/FirstLogin")
  )
);

const Settings = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Settings" */ "./pages/Settings"))
);

const OrganisationList = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "OrganisationList" */ "./pages/Settings/Organisation/OrganisationList"
    )
  )
);

const OrganisationDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "OrganisationDetails" */ "./pages/Settings/Organisation/OrganisationDetails"
    )
  )
);

const Projects = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Projects" */ "./pages/Projects"))
);

const ProjectDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ProjectDetails" */ "./pages/Project/ProjectDetails"
    )
  )
);

const Profile = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Profile" */ "./pages/Profile"))
);

const Assets = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Assets" */ "./pages/Project/UploadData/Assets")
  )
);

const Network = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Network" */ "./pages/Project/UploadData/Assets/Network"
    )
  )
);

const Attributes = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Attributes" */ "./pages/Project/UploadData/Assets/Attributes"
    )
  )
);

const MeterProfiles = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "MeterProfiles" */ "./pages/Project/UploadData/Assets/MeterProfiles"
    )
  )
);

const Scenarios = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Scenarios" */ "./pages/Project/UploadData/Scenarios"
    )
  )
);

const InputScenarios = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "InputScenarios" */ "./pages/Project/UploadData/Scenarios/Scenarios"
    )
  )
);

const CustomerClasses = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "CustomerClasses" */ "./pages/Project/UploadData/CustomerClasses"
    )
  )
);

const Technology = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Technology" */ "./pages/Project/UploadData/Technology"
    )
  )
);

const Technologies = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Technologies" */ "./pages/Project/UploadData/Technology/Technologies"
    )
  )
);

const Battery = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Battery" */ "./pages/Project/UploadData/Technology/Battery"
    )
  )
);

const Solar = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Solar" */ "./pages/Project/UploadData/Technology/Solar"
    )
  )
);

const SolarProfiles = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "SolarProfiles" */ "./pages/Project/UploadData/Technology/SolarProfiles"
    )
  )
);

const RebateCurves = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "RebateCurves" */ "./pages/Project/UploadData/Technology/RebateCurves"
    )
  )
);

const CostCurves = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "CostCurves" */ "./pages/Project/UploadData/Technology/CostCurves"
    )
  )
);

const Products = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Products" */ "./pages/Project/UploadData/Technology/Products"
    )
  )
);

const Appliance = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Appliance" */ "./pages/Project/UploadData/Appliance"
    )
  )
);

const Appliances = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Appliances" */ "./pages/Project/UploadData/Appliance/Appliances"
    )
  )
);

const AppCostCurves = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "AppCostCurves" */ "./pages/Project/UploadData/Appliance/CostCurves"
    )
  )
);

const SubloadMagnitudes = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "SubloadMagnitudes" */ "./pages/Project/UploadData/Appliance/SubloadMagnitudes"
    )
  )
);

const SubloadIntervals = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "SubloadIntervals" */ "./pages/Project/UploadData/Appliance/SubloadIntervals"
    )
  )
);

const Tariff = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Tariff" */ "./pages/Project/UploadData/Tariff")
  )
);

const DefaultTariffs = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "DefaultTariffs" */ "./pages/Project/UploadData/Tariff/DefaultTariffs"
    )
  )
);

const FeedInTariffGrowth = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "FeedInTariffGrowth" */ "./pages/Project/UploadData/Tariff/FeedInTariffGrowth"
    )
  )
);

const GasDailyBlock = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "GasDailyBlock" */ "./pages/Project/UploadData/Tariff/GasDailyBlock"
    )
  )
);

const GasDeclBlock = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "GasDeclBlock" */ "./pages/Project/UploadData/Tariff/GasDeclBlock"
    )
  )
);

const GasTariffs = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "GasTariffs" */ "./pages/Project/UploadData/Tariff/GasTariffs"
    )
  )
);

const TariffComponents = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "TariffComponents" */ "./pages/Project/UploadData/Tariff/TariffComponents"
    )
  )
);

const Tariffs = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Tariffs" */ "./pages/Project/UploadData/Tariff/Tariffs"
    )
  )
);

const Vehicle = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Vehicle" */ "./pages/Project/UploadData/Vehicle"
    )
  )
);

const VehicleCostCurves = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "VehicleCostCurves" */ "./pages/Project/UploadData/Vehicle/CostCurves"
    )
  )
);

const EvIntervals = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "EvIntervals" */ "./pages/Project/UploadData/Vehicle/EvIntervals"
    )
  )
);

const EvModelAvailability = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "EvModelAvailability" */ "./pages/Project/UploadData/Vehicle/EvModelAvailability"
    )
  )
);

const EvTariffUptake = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "EvTariffUptake" */ "./pages/Project/UploadData/Vehicle/EvTariffUptake"
    )
  )
);

const FuelCost = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "FuelCost" */ "./pages/Project/UploadData/Vehicle/FuelCost"
    )
  )
);

const VehicleStock = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "VehicleStock" */ "./pages/Project/UploadData/Vehicle/VehicleStock"
    )
  )
);

const ChargingLocations = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ChargingLocations" */ "./pages/Project/UploadData/Vehicle/ChargingLocations"
    )
  )
);

const PreferredChargingLocation = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "PreferredChargingLocation" */ "./pages/Project/UploadData/Vehicle/PreferredChargingLocation"
    )
  )
);

const Vehicles = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "Vehicles" */ "./pages/Project/UploadData/Vehicle/Vehicles"
    )
  )
);

const Agent = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Agent" */ "./pages/Project/UploadData/Agent")
  )
);

const CustomerAnnualConsumption = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "CustomerAnnualConsumption" */ "./pages/Project/UploadData/Agent/CustomerAnnualConsumption"
    )
  )
);

const CustomerConsumptionBands = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "CustomerConsumptionBands" */ "./pages/Project/UploadData/Agent/CustomerConsumptionBands"
    )
  )
);

const CustomerGrowth = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "CustomerGrowth" */ "./pages/Project/UploadData/Agent/CustomerGrowth"
    )
  )
);

const CustomerIntervals = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "CustomerIntervals" */ "./pages/Project/UploadData/Agent/CustomerIntervals"
    )
  )
);

const ApplianceBreakdown = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ApplianceBreakdown" */ "./pages/Project/UploadData/ApplianceBreakdown"
    )
  )
);

const Roi = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Roi" */ "./pages/Project/UploadData/Roi")
  )
);

const AssetHierarchy = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "AssetHierarchy" */ "./pages/Project/UploadData/Assets/AssetHierarchy"
    )
  )
);

const VehicleProducts = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "VehicleProducts" */ "./pages/Project/UploadData/Vehicle/VehicleProducts"
    )
  )
);

const ApplianceProducts = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ApplianceProducts" */ "./pages/Project/UploadData/Appliance/ApplianceProducts"
    )
  )
);

const ManageSimulations = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ManageSimulations" */ "./pages/Project/ManageSimulations/ManageSimulations"
    )
  )
);

const AgentDesign = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "AgentDesign" */ "./pages/Project/UploadData/Agent/AgentDesign"
    )
  )
);

const CustomerGrowthResult = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "CustomerGrowthResult" */ "./pages/Project/UploadData/Agent/CustomerGrowthResult"
    )
  )
);

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        { path: "", element: <Login /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "first-login", element: <FirstLogin /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "logout", element: <Logout /> },
      ],
    },
    {
      path: "/home",
      element: <AuthLayout />,
      children: [{ path: "", element: <Navigate to="/projects" replace /> }],
    },
    {
      path: "/projects",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Projects /> },
        { path: "details/:id", element: <ProjectDetails /> },
        {
          path: "details/:id/:organisation",
          element: <ProjectDetails />,
        },
      ],
    },
    {
      path: "/settings",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Settings /> },
        {
          path: "organisation",
          element: <Navigate to="/settings/organisation/list" replace />,
        },
        { path: "organisation/list", element: <OrganisationList /> },
        {
          path: "organisation/details/:id",
          element: <OrganisationDetails source="organisation" />,
        },
        {
          path: "organisation-settings/:id",
          element: <OrganisationDetails />,
        },
      ],
    },
    {
      path: "/profile",
      element: <AuthLayout />,
      children: [{ path: "", element: <Profile /> }],
    },
    {
      path: "/project",
      element: <ProjectLayout />,
      children: [
        { path: "", element: <Projects /> },
        { path: "upload-data/:id", element: <Assets /> },
        {
          path: "upload-data/:id/assets",
          children: [
            { path: "", element: <Assets /> },
            { path: "financials", element: <Network /> },
            { path: "attributes", element: <Attributes /> },
            { path: "measurements", element: <MeterProfiles /> },
            { path: "asset-hierarchy", element: <AssetHierarchy /> },
          ],
        },
        {
          path: "upload-data/:id/scenarios",
          children: [
            { path: "", element: <Scenarios /> },
            { path: "in-scenarios", element: <InputScenarios /> },
            { path: "customer-classes", element: <CustomerClasses /> },
          ],
        },
        {
          path: "upload-data/:id/customerClasses",
          children: [{ path: "", element: <CustomerClasses /> }],
        },
        {
          path: "upload-data/:id/technology",
          children: [
            { path: "", element: <Technology /> },
            { path: "technologies", element: <Technologies /> },
            { path: "battery", element: <Battery /> },
            { path: "solar", element: <Solar /> },
            { path: "solar-profiles", element: <SolarProfiles /> },
            { path: "rebate-curves", element: <RebateCurves /> },
            { path: "cost-curves", element: <CostCurves /> },
            { path: "products", element: <Products /> },
          ],
        },
        {
          path: "upload-data/:id/appliance",
          children: [
            { path: "", element: <Appliance /> },
            { path: "appliances", element: <Appliances /> },
            { path: "cost-curves", element: <AppCostCurves /> },
            { path: "subload-magnitudes", element: <SubloadMagnitudes /> },
            { path: "subload-intervals", element: <SubloadIntervals /> },
            { path: "products", element: <ApplianceProducts /> },
          ],
        },
        {
          path: "upload-data/:id/tariff",
          children: [
            { path: "", element: <Tariff /> },
            { path: "default-tariffs", element: <DefaultTariffs /> },
            { path: "feed-in-tariff-growth", element: <FeedInTariffGrowth /> },
            { path: "gas-daily-block", element: <GasDailyBlock /> },
            { path: "gas-decl-block", element: <GasDeclBlock /> },
            { path: "gas-tariffs", element: <GasTariffs /> },
            { path: "tariff-components", element: <TariffComponents /> },
            { path: "tariffs", element: <Tariffs /> },
          ],
        },
        {
          path: "upload-data/:id/vehicle",
          children: [
            { path: "", element: <Vehicle /> },
            { path: "vehicles", element: <Vehicles /> },
            { path: "cost-curves", element: <VehicleCostCurves /> },
            { path: "ev-intervals", element: <EvIntervals /> },
            { path: "model-availability", element: <EvModelAvailability /> },
            { path: "ev-tariff-uptake", element: <EvTariffUptake /> },
            { path: "fuel-cost", element: <FuelCost /> },
            { path: "vehicle-stock", element: <VehicleStock /> },
            { path: "charging-locations", element: <ChargingLocations /> },
            {
              path: "preferred-charging-location",
              element: <PreferredChargingLocation />,
            },
            { path: "products", element: <VehicleProducts /> },
          ],
        },
        {
          path: "upload-data/:id/agent",
          children: [
            { path: "", element: <Agent /> },
            {
              path: "customer-annual-consumption",
              element: <CustomerAnnualConsumption />,
            },
            {
              path: "customer-consumption-bands",
              element: <CustomerConsumptionBands />,
            },
            {
              path: "agent-design",
              element: <AgentDesign />,
            },
            { path: "customerGrowth", element: <CustomerGrowth /> },
            { path: "customer-intervals", element: <CustomerIntervals /> },
            {
              path: "customer-growth-results",
              element: <CustomerGrowthResult />,
            },
          ],
        },
        {
          path: "upload-data/:id/applianceBreakdown",
          children: [{ path: "", element: <ApplianceBreakdown /> }],
        },
        { path: "upload-data/:id/roi-curves", element: <Roi /> },
        { path: "manage-simulations/:id", element: <ManageSimulations /> },
      ],
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}
