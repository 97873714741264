import { Box, Typography, Button, Tabs, Tab } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { LockOutlined, ErrorOutline, HighlightOff } from "@mui/icons-material";
import { getLastInputError, resetLastInputError } from "actions/validation";
import { useEffect, useState } from "react";
import { projectHeaderTab, projectHeaderTabs } from "constants/project";
import { isEmpty } from "lodash";
import SimulationRunModal from "./SimulationRunModal";
import { clearSimReady } from "actions/projects";

const tabStyles = (theme) => {
  return {
    padding: "0 40px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
    borderBottom: "1px solid #E0E5F0",
    "& .lock-icon": {
      marginLeft: "4px",
      fontSize: "16px",
    },
  };
};

const styles = () => {
  return {
    height: "65px",
    padding: "0 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderBottom: "1px solid #E0E5F0",
    "& .project-header-title": {
      display: "flex",
      alignItems: "center",
    },
    "& .project-header-buttons": {
      display: "flex",
      alignItems: "center",
    },
  };
};

const lastErrorStyles = () => {
  return {
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    color: "white",
    backgroundColor: "#e74c3c",
    padding: "2px 10px",
    "& .error-message": {
      margin: "0 8px",
      flexGrow: 1,
    },
    "& .closeIcon": {
      cursor: "pointer",
      marginRight: "16px",
    },
  };
};

const LastError = ({ error, onClickClose }) => (
  <Box sx={lastErrorStyles}>
    <ErrorOutline />
    <Box className="error-message">{error}</Box>
    <HighlightOff className="closeIcon" onClick={onClickClose} />
  </Box>
);

const ProjectHeader = ({ activeTab, onChangeTab, onClickClose }) => {
  const dispatch = useDispatch();

  const [simReady, setSimReady] = useState(false);
  const [alertSimReady, setAlertSimReady] = useState(false);

  const project = useSelector((state) => state.projects?.details);
  const lastInputError = useSelector(
    (state) => state.validation?.lastInputError
  );
  const isSimReady = useSelector((state) => state.projects?.isSimReady);

  const utcDate = new Date(project?.last_modified);

  useEffect(() => {
    if (
      project?.item &&
      activeTab === projectHeaderTab.upload &&
      isEmpty(project?.sim_status)
    ) {
      setSimReady(isSimReady);
      setAlertSimReady(isSimReady);
    }
  }, [isSimReady]);

  useEffect(() => {
    if (
      project?.item &&
      project?.data_completion_count > 0 &&
      activeTab === projectHeaderTab.upload
    ) {
      const interval = setInterval(() => {
        dispatch(getLastInputError(project.item, project.organisation));
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [project, activeTab]);

  // Adjust the date and time components to the user's local timezone
  const utcCalendarDate = new Date(
    Date.UTC(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
      utcDate.getUTCHours(),
      utcDate.getUTCMinutes()
    )
  );
  const localDate = new Date(
    Date.UTC(
      utcCalendarDate.getFullYear(),
      utcCalendarDate.getMonth(),
      utcCalendarDate.getDate(),
      utcCalendarDate.getHours(),
      utcCalendarDate.getMinutes()
    )
  );
  // Format the data
  const formattedDate = localDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const onClickCloseError = () => {
    if (project?.item) {
      dispatch(resetLastInputError(project.item, project.organisation));
    }
  };

  const isValidated = () => {
    let validated = true;
    for (let i = 1; i <= 10; i++) {
      const key = `step_${i}_validation`;
      validated = validated && project[key] === "True";
    }

    return validated;
  };

  const isDisabled = (item) => {
    if (item.disabled) {
      return true;
    }

    if (item.validation) {
      if (!project?.item) return true;

      return !isValidated();
    }

    return false;
  };

  const onCloseSimulationRunModal = () => {
    setSimReady(false);
    setTimeout(() => {
      setAlertSimReady(false);
      dispatch(clearSimReady());
    }, 10000);
  };

  return (
    <Box>
      {lastInputError && (
        <LastError error={lastInputError} onClickClose={onClickCloseError} />
      )}
      <Box sx={styles}>
        <Box className="project-header-title">
          <Typography variant="h4" mr={1}>
            {project?.project_name}
          </Typography>
          {project?.last_modified && (
            <Typography
              variant="body2"
              fontWeight={500}
              color="#7D818B"
              mt={0.5}
            >
              Last Updated: {formattedDate}
            </Typography>
          )}
        </Box>
        <Box className="project-header-buttons">
          <Button onClick={onClickClose} variant="outlined" sx={{ mr: 1 }}>
            Close
          </Button>
          <Button variant="contained">Save</Button>
        </Box>
      </Box>
      <Box sx={tabStyles}>
        <Tabs
          sx={{
            minHeight: "32px",
          }}
          value={activeTab}
          onChange={(e, value) => onChangeTab(value)}
        >
          {projectHeaderTabs &&
            projectHeaderTabs.map((item, i) => (
              <Tab
                sx={{
                  padding: "12px 4px",
                  minHeight: "32px",
                  margin: "0 12px",
                  "&:first-of-type": {
                    marginLeft: "0",
                  },
                  color: "#1f497d",
                  backgroundColor:
                    alertSimReady && item.value === projectHeaderTab.simulations
                      ? "#FFFF99"
                      : undefined,
                }}
                key={i}
                value={item.value}
                label={item.label}
                icon={
                  isDisabled(item) && (
                    <LockOutlined fontSize="small" className="lock-icon" />
                  )
                }
                iconPosition="end"
                disabled={isDisabled(item)}
              />
            ))}
        </Tabs>
      </Box>

      {simReady && activeTab === projectHeaderTab.upload && (
        <SimulationRunModal open={true} onClose={onCloseSimulationRunModal} />
      )}
    </Box>
  );
};

export default ProjectHeader;
