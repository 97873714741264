import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Content, Sidebar } from "components";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { isNil } from "lodash";

const wrapperStyle = () => {
  return {
    display: "flex",
    minHeight: "100vh",
  };
};

const pageStyle = () => {
  return {
    display: "flex",
    flexDirection: "column",
  };
};

const AuthLayout = () => {
  const [contentWidth, setContentWidth] = useState("100%");

  const navigate = useNavigate();
  const session = useSelector((state) => state.session.session);
  const loading = useSelector((state) => state.session.loading);

  useEffect(() => {
    if (isNil(session) && !loading) {
      navigate("/");
    }
  }, [session]);

  const onOpenChange = (open) => {
    setContentWidth(open ? "calc(100vw - 320px)" : "100%");
  };

  return (
    <Box sx={pageStyle}>
      <Box sx={wrapperStyle}>
        <Sidebar onOpenChange={onOpenChange} />
        <Content width={contentWidth}>
          <Outlet />
        </Content>
      </Box>
    </Box>
  );
};

export default AuthLayout;
