import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const styles = () => {
  return {
    width: "100%",
    "& .MuiDataGrid-root": {
      fontSize: "13px",
      borderRadius: 0,
      marginBottom: "12px",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#E9ECF5",
      borderBottom: "1px solid #D6DDEA",
      borderRadius: 0,
      "& .MuiDataGrid-withBorderColor": {
        borderColor: "#D6DDEA",
      },
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "unset !important",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "unset !important",
    },
    "& .MuiDataGrid-row": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "white",
      minHeight: "40px",
      "& .MuiDataGrid-selectedRowCount": {
        visibility: "hidden",
      },
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "40px",
      "& p": {
        marginTop: "8px",
        marginBottom: "8px",
      },
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowY: "scroll !important",
      maxHeight: "calc(100vh - 360px)",
    },
  };
};

const DataTable = ({
  rows = [],
  columns = [],
  loading = false,
  onRowClick = () => {},
  hideFooter = false,
}) => {
  // ATTEMPT 1 -------------------------------------
    // // Define a function to format numerical values to 2 decimal places
    // const formatNumber = (value) => {
    //   return parseFloat(value).toFixed(2);
    // };
  
    // // Map through the columns and add a valueGetter function to format numerical values
    // const formattedColumns = columns.map((column) => ({
    //   ...column,
    //   valueGetter: (params) =>
    //     column.field && params.value && typeof params.value === "number"
    //       ? formatNumber(params.value)
    //       : params.value,
    // }));

  // ATTEMPT 2 -------------------------------------
  // Define a function to format numerical values to 2 decimals if they have 3 or more decimals
  // const formatNumber = (value) => {
  //   const numDecimals = (value.toString().split('.')[1] || []).length;
  //   return numDecimals >= 3 ? parseFloat(value).toFixed(2) : value;
  // };

  // // Map through the columns and add a valueGetter function to format numerical values
  // const formattedColumns = columns.map((column) => ({
  //   ...column,
  //   valueGetter: (params) =>
  //     column.field && params.value && typeof params.value === "number"
  //       ? formatNumber(params.value)
  //       : params.value,
  // }));

  // ATTEMPT 3 -------------------------------------
    // Define a function to format numerical values to 2 decimals if they have 3 or more decimals
    const formatNumber = (value) => {
      if (typeof value === "number") {
        if (value.toString().includes('.') && value.toString().split('.')[1].length >= 3) {
          return parseFloat(value).toFixed(2);
        }
      }
      return value;
    };
  
    // Map through the columns and add a valueGetter function to format numerical values
    const formattedColumns = columns.map((column) => ({
      ...column,
      valueGetter: (params) =>
        column.field ? formatNumber(params.value) : params.value,
    }));

  return (
    <Box sx={styles}>
      <DataGrid
        rows={rows}
        // columns={columns}
        columns={formattedColumns} // Use the formatted columns
        autoHeight={true}
        loading={loading}
        rowHeight={40}
        columnHeaderHeight={40}
        disableColumnSelector={true}
        disableColumnFilter={true}
        showColumnVerticalBorder={true}
        onRowClick={onRowClick}
        hideFooter={hideFooter}
      />
    </Box>
  );
};

export default DataTable;
