import { Box, Modal as MuiModal, Typography, IconButton } from "@mui/material";
import { styles, header } from "./modalStyles";
import { Close } from "@mui/icons-material";

const Modal = ({
  title,
  open,
  onClose,
  children,
  width = 400,
  titleVariant = "h5",
}) => {
  return (
    <MuiModal open={open} onClose={onClose}>
      <Box sx={() => styles(width)} className="modal-section">
        <Box sx={header}>
          <Typography variant={titleVariant}>{title}</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box>{children}</Box>
      </Box>
    </MuiModal>
  );
};

export default Modal;
