import { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { Suspense } from "react";
import Router from "./routes";
import ThemeConfig from "./theme";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SessionCheck from "pages/Session/SessionCheck";
import { Loader } from "components";
import { getProfile } from "actions/profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkSession } from "actions/session";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const error = useSelector((state) => state.session.error);
  const user = useSelector((state) => state.session.user);
  const session = useSelector((state) => state.session.session);

  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  useEffect(() => {
    if (error?.code === "PasswordResetRequiredException") {
      navigate("/change-password");
    }
  }, [error]);

  useEffect(() => {
    if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
      navigate("/first-login");
    } else {
      if (
        session &&
        ["/", "/login", "/first-login", "/change-password"].includes(
          location.pathname
        )
      ) {
        navigate("/projects");
      }
    }
  }, [user, session]);

  useEffect(() => {
    if (session && user && user?.username) {
      dispatch(getProfile(user?.username));
    }
  }, [session, dispatch]);

  return (
    <ThemeConfig>
      <CssBaseline />
      <SessionCheck />
      <Suspense fallback={<Loader show={true} fullPage={true} />}>
        <Router />
      </Suspense>
      <ToastContainer
        autoClose={3000}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
    </ThemeConfig>
  );
}

export default App;
